<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'VehicleNew' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="vehicles.itemsPerPage" id="perPageSelect" size="sm" :options="vehicles.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="vehicles.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="vehicles.fields" :filter="vehicles.filter" :current-page="vehicles.currentPage"
                 :per-page="vehicles.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="vehicles.sortBy" :sort-desc="vehicles.sortDesc"
                 ref="vehiclesTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in vehicles.fields" :key="field.key"
                          :class="vehicles.filters[index] && vehicles.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="vehicles.filters[index]">
                            <b-col v-if="vehicles.filters[index].inputType === 'Select'">
                                <b-select v-model="vehicles.filters[index].value">
                                    <b-select-option value="">Todos</b-select-option>
                                    <b-select-option value="TRUE">Activo</b-select-option>
                                    <b-select-option value="FALSE">Inactivo</b-select-option>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="vehicles.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="vehicles.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" v-if="row.item.enabled" class="p-1">Activo</b-badge>
                <b-badge variant="warning" v-else>Inactivo</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'VehicleShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" :to="{ name: 'VehicleEdit', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Modificar" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover :title="row.item.enabled ? 'Desactivar' : 'Activar'"
                              variant="outline-primary" @click="changeStatus(row.item)">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ vehicles.totalRows }} registro<template v-if="vehicles.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="vehicles.currentPage" :total-rows="vehicles.totalRows"
                                      :per-page="vehicles.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { vehicleListUrl, vehicleEnableUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            vehicles: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'brand', label: 'Marca', sortable: true },
                    { key: 'model', label: 'Modelo', sortable: true },
                    { key: 'year', label: 'Año', sortable: true, class: 'text-right' },
                    { key: 'licensePlate', label: 'Placa', sortable: true, class: 'text-right' },
                    { key: 'passengerCapacity', label: 'Pasajeros', sortable: true, class: 'text-right' },
                    { key: 'enabled', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'v.brand', type: 'STRING', value: '', format: '' },
                    { column: 'v.model', type: 'STRING', value: '', format: '' },
                    { column: 'v.year', type: 'STRING', value: '', format: '' },
                    { column: 'v.license_plate', type: 'STRING', value: '', format: '' },
                    { column: 'v.passenger_capacity', type: 'STRING', value: '', format: '' },
                    { column: 'v.enabled', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'v.brand, v.model, v.license_plate',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Vehículos',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.vehicles, this.tableState);
    },
    methods: {
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.vehicles.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(vehicleListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.vehicles.totalRows = response.data.totalRows;
                        return response.data.vehicles;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        changeStatus(vehicle) {
            this.$swal({
                title: `¿${vehicle.enabled ? 'Desactivar' : 'Activar'} el vehículo ${vehicle.brand} ${vehicle.model} ${vehicle.year}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(vehicleEnableUrl(), {
                        vehicleId: vehicle.id,
                        enable: !vehicle.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            vehicle.enabled = !vehicle.enabled;

                            this.$bvToast.toast(response.data.message, {
                                title: 'Vehículos',
                                variant: 'success'
                            });

                            this.$refs.vehiclesTable.refresh();
                            this.$emit('get-setting');
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar el estatus.', {
                            title: 'Vehículos',
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.vehicles.sortBy = context.sortBy;
            this.vehicles.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.vehicles);
        }
    },
    watch: {
        'vehicles.filters': {
            handler() {
                this.$refs.vehiclesTable.refresh();
                this.saveTableState(this.$route.name, this.vehicles);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.vehicles.totalRows === 0 ? 0 : (this.vehicles.currentPage - 1) * this.vehicles.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.vehicles.currentPage - 1) * this.vehicles.itemsPerPage;

            return (this.vehicles.totalRows - offset) < this.vehicles.itemsPerPage ? this.vehicles.totalRows : offset + this.vehicles.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>